import { Dispatch } from 'redux';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { UnknownFunction } from '@eon-home/react-library';

import {
    AutopilotChargingModel,
    BaseAutocompletePredictionsModel,
    ElectricCarApi,
    ElectricCarChargingModeCommandModelV1ActionEnum as CommandsEnum,
    ElectricCarHistorySessionV2ResponseModel,
    ElectricCarResponseModelV1,
    GenericSmartChargingModel,
    GenericSmartChargingModelModeEnum,
    GoogleAPILocationRequestModel as ElectricCarAddress,
    GoogleAPIResponseResultModel,
    LocationApi,
    PlaceDetailsResult,
    PvBatteryApi,
    PvForecastChargingModel,
    ResponseError,
    ScheduleChargingModel,
} from '@swagger-http';

import { store } from '@src/index';
import { Scope } from '@tools/enums';
import {
    AggregatedDataUserState,
    ChargingModeModel,
    EmobilityAction,
} from '@store/types';
import { handleHistoricalEmobilityData, setSuccessToast } from '@store/actions';
import { useElectricCarConfigId } from '@store/selectors';
import {
    EmobilityActionTypes,
    HistoricalResolution,
    LiveDataActionTypes,
} from '@store/enums';
import {
    checkForScopes,
    createRequestConfiguration,
    getComparisonInterval,
    getRequestDates,
    getResolution,
    handleError,
    useHasAutopilotChargingWriteScope,
} from '@tools/utils';

const {
    Schedule,
    Autopilot,
    PvForecast,
    // SolarAssistedCharging,
    // GridFriendlyCharging,
    // DynamicPriceCharging,
    // HomePlugAndCharge,
} = GenericSmartChargingModelModeEnum;

export interface EmobilityAddressDataProps {
    configId: string;
    address: ElectricCarAddress;
    isAddressSet: boolean;
}

export interface TransformedLocationDetailsResult {
    placeId: string;
    formattedAddress: string;
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
    lat: number;
    lng: number;
}

export const AUTOPILOT_DEFAULT_TARGET_SOC = 80;
export const AUTOPILOT_DEFAULT_DEPATURE_TIME = '0700';
export const AUTOPILOT_DEFAULT_MINIMUM_SOC_IF_MERCEDES = 50;
export const PVFORECAST_DEFAULT_TARGET_SOC = 80;
export const PVFORECAST_DEFAULT_DEPATURE_TIME = '0700';
export const PVFORECAST_DEFAULT_THRESHOLD = 25;

export const getElectricCarData =
    () =>
    (dispatch: Dispatch<any>): Promise<EmobilityAction | void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_READ])) {
            return Promise.resolve();
        }

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarListElectricCars()
            .then((data: ElectricCarResponseModelV1[]) => {
                return dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_SET_DEVICE_DATA,
                    payload: {
                        electricCar: data[0],
                    },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error when getting the electric car data:',
                );

                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_SET_ERROR,
                    payload: {
                        error: e?.response,
                    },
                });
            });
    };

const setEvStateLoading = (evStateLoading: boolean) => ({
    type: LiveDataActionTypes.ELECTRIC_CAR_TELEMETRIES,
    payload: {
        evStateLoading,
    },
});

const setEvCommandError = (error: boolean) => ({
    type: LiveDataActionTypes.ELECTRIC_CAR_TELEMETRIES,
    payload: {
        commandError: error,
    },
});

export const sendElectricCarCommand =
    (configId: string, command: CommandsEnum) =>
    (dispatch: Dispatch<any>): Promise<EmobilityAction | void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_WRITE])) {
            return Promise.resolve();
        }

        dispatch(setEvCommandError(false));
        dispatch({
            type: LiveDataActionTypes.ELECTRIC_CAR_TELEMETRIES,
            payload: {
                evStateLoading: true,
                lastCommand: command,
            },
        });

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarSetChargingMode({
                configId,
                electricCarChargingModeCommandModelV1: {
                    action: command,
                },
            })
            .then((data) => {
                if (data.failureReason) {
                    dispatch(setEvCommandError(true));
                    dispatch(setEvStateLoading(false));
                    return;
                }
            })
            .catch(async (e: ResponseError) => {
                dispatch(setEvCommandError(true));
                dispatch(setEvStateLoading(false));

                await handleError(
                    e,
                    'Error when calling electricCarSetChargingMode:',
                );
            });
    };

const transformLocationData = (
    locationDetails: PlaceDetailsResult | GoogleAPIResponseResultModel,
): TransformedLocationDetailsResult => {
    const transformedPlaceDetails: TransformedLocationDetailsResult = {
        placeId: '',
        formattedAddress: '',
        street: '',
        city: '',
        houseNumber: '',
        zipCode: '',
        lat: 0,
        lng: 0,
    };

    locationDetails.address_components.forEach((item) => {
        if (item.types.includes('route')) {
            transformedPlaceDetails.street = item.long_name;
        }
        if (item.types.includes('street_number')) {
            transformedPlaceDetails.houseNumber = item.long_name;
        }
        if (item.types.includes('postal_code')) {
            transformedPlaceDetails.zipCode = item.long_name;
        }
        if (
            item.types.includes('locality') ||
            item.types.includes('postal_town')
        ) {
            transformedPlaceDetails.city = item.long_name;
        }
    });

    transformedPlaceDetails.placeId = locationDetails.place_id;
    transformedPlaceDetails.formattedAddress =
        locationDetails.formatted_address;
    transformedPlaceDetails.lat = locationDetails.geometry.location.lat;
    transformedPlaceDetails.lng = locationDetails.geometry.location.lng;

    return transformedPlaceDetails;
};

export const getLocationsByAddress = async (
    address: string,
): Promise<BaseAutocompletePredictionsModel[] | null> => {
    if (!checkForScopes([Scope.ME_READ])) {
        return Promise.resolve([]);
    }

    try {
        return await new LocationApi(
            createRequestConfiguration(),
        ).locationGetAddressPredictions({ address });
    } catch (e) {
        await handleError(e, 'Error getting locations by address data:');

        return null;
    }
};

export const getLocationDetailsByPlaceId = async (
    placeId: string,
): Promise<TransformedLocationDetailsResult | null> => {
    if (!checkForScopes([Scope.ME_READ])) {
        return Promise.resolve(null);
    }

    try {
        const locationDetails = await new LocationApi(
            createRequestConfiguration(),
        ).locationGetLocationDetails({ placeId });

        return transformLocationData(locationDetails);
    } catch (e) {
        await handleError(e, 'Error getting location details by placeId data:');

        return null;
    }
};

export const getLocationByLatitudeAndLongitude = async (
    lat: string,
    lng: string,
): Promise<TransformedLocationDetailsResult | null> => {
    if (!checkForScopes([Scope.ME_READ])) {
        return Promise.resolve(null);
    }

    try {
        const locationDetails = await new LocationApi(
            createRequestConfiguration(),
        ).locationGetAddressByLatitudeAndLongitude({ lat, lng });

        return transformLocationData(locationDetails);
    } catch (e) {
        await handleError(
            e,
            'Error getting location details by latitude and longitude:',
        );

        return null;
    }
};

export const setAddress =
    (addressData: EmobilityAddressDataProps, isMercedes: boolean = false) =>
    (dispatch: Dispatch): Promise<boolean | void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_WRITE])) {
            return Promise.resolve(false);
        }

        const hasAutopilotChargingScope = useHasAutopilotChargingWriteScope();

        const isFirstTimeAddressIsSet = !addressData.isAddressSet;

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarSetAddress({
                configId: addressData.configId,
                googleAPILocationRequestModel: addressData.address,
            })
            .then(() => {
                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_SET_ADDRESS,
                });

                // automatically activate autopilot mode if the address is set for the first time
                if (hasAutopilotChargingScope && isFirstTimeAddressIsSet) {
                    dispatch(
                        setElectricCarAutopilotMode(addressData.configId, {
                            active: true,
                            departureTime: AUTOPILOT_DEFAULT_DEPATURE_TIME,
                            targetSoc: AUTOPILOT_DEFAULT_TARGET_SOC,
                            minimumSoc: isMercedes
                                ? AUTOPILOT_DEFAULT_MINIMUM_SOC_IF_MERCEDES
                                : undefined,
                        }),
                    );
                }

                return true;
            })
            .catch(async (e: ResponseError) => {
                // address does not have to be changed when backend returns error
                await handleError(e, 'Error when setting address:');

                return false;
            });
    };

export const getElectricCarChargingModesData =
    (configId: string, silent = true) =>
    (dispatch: Dispatch): Promise<void> => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ])) {
            return Promise.resolve();
        }

        if (!silent) {
            dispatch(setElectricCarChargingModesLoading(true));
        }

        dispatch(setElectricCarChargingModesError(false));

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarGetSmartChargingModes({ configId })
            .then((data: GenericSmartChargingModel[]) => {
                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_DATA,
                    payload: {
                        data,
                    },
                });
            })
            .catch(async (e) => {
                if (e.response.status !== 404) {
                    await handleError(e, `Error calling GetSmartChargingMode:`);

                    dispatch(setElectricCarChargingModesError(true));
                }
            })
            .finally(() => dispatch(setElectricCarChargingModesLoading(false)));
    };

export const setElectricCarChargingModesError =
    (error: boolean) => (dispatch: Dispatch) =>
        dispatch({
            type: EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_ERROR,
            payload: {
                error,
            },
        });

export const setElectricCarChargingModesLoading =
    (loading: boolean) => (dispatch: Dispatch) =>
        dispatch({
            type: EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_LOADING,
            payload: {
                loading,
            },
        });

export const setElectricCarChargingModeError =
    (mode: GenericSmartChargingModelModeEnum, error: ResponseError) =>
    (dispatch: Dispatch) => {
        let hasMatch = false;
        const data = store
            .getState()
            .emobility.electricCarData.chargingModes.data.map((item) => {
                if (!hasMatch) {
                    hasMatch = item.mode === mode;
                }

                return {
                    ...item,
                    error: hasMatch ? error : item.error,
                };
            });

        if (!hasMatch) {
            data.push({
                mode,
                error,
                parameters: { active: false },
            });
        }

        dispatch({
            type: EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODE_ERROR,
            payload: {
                data,
            },
        });
    };

export const toggleElectricCarChargingModes =
    (mode: GenericSmartChargingModelModeEnum, isActive: boolean) =>
    (dispatch: Dispatch) => {
        const data = store
            .getState()
            .emobility.electricCarData.chargingModes.data.map((item) => ({
                ...item,
                parameters: {
                    ...item.parameters,
                    active: item.mode === mode ? isActive : false,
                },
            }));

        dispatch({
            type: EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_DATA,
            payload: {
                data,
            },
        });
    };

export const onSuccessfulElectricCarChargingModeChange =
    (configId: string) => (dispatch: Dispatch) => {
        dispatch(setSuccessToast());
        dispatch(getElectricCarChargingModesData(configId));
    };

export const setElectricCarSmartSchedulingMode =
    (
        configId: string,
        scheduleChargingModel: ScheduleChargingModel,
        callback?: UnknownFunction,
    ) =>
    (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_WRITE])) {
            return Promise.resolve();
        }

        dispatch(
            toggleElectricCarChargingModes(
                Schedule,
                scheduleChargingModel.active,
            ),
        );

        if (typeof callback === 'function') {
            callback();
        }

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarSetSmartChargingModeToSchedule({
                configId,
                scheduleChargingModel,
                takeInstantEffect: 'true',
            })
            .then(() => {
                dispatch(onSuccessfulElectricCarChargingModeChange(configId));
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    `Error calling SetSmartChargingModeToSchedule :`,
                );

                dispatch(setElectricCarChargingModeError(Schedule, e));
            });
    };

const setElectricCarAutopilotMode =
    (
        configId: string,
        autopilotChargingModel: AutopilotChargingModel,
        callback?: UnknownFunction,
    ) =>
    (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_WRITE])) {
            return Promise.resolve();
        }

        dispatch(
            toggleElectricCarChargingModes(
                Autopilot,
                autopilotChargingModel.active,
            ),
        );

        if (typeof callback === 'function') {
            callback();
        }

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarSetSmartChargingModeToAutopilot({
                configId,
                autopilotChargingModel,
                takeInstantEffect: 'true',
            })
            .then(() => {
                dispatch(onSuccessfulElectricCarChargingModeChange(configId));
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    `Error calling SetSmartChargingModeToAutopilot:`,
                );

                dispatch(setElectricCarChargingModeError(Autopilot, e));
            });
    };

export const setElectricCarPvForecastMode =
    (
        configId: string,
        pvForecastChargingModel: PvForecastChargingModel,
        callback?: UnknownFunction,
    ) =>
    (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_WRITE])) {
            return Promise.resolve();
        }

        dispatch(
            toggleElectricCarChargingModes(
                PvForecast,
                pvForecastChargingModel.active,
            ),
        );

        if (typeof callback === 'function') {
            callback();
        }

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarSetSmartChargingModeToPvForecast({
                configId,
                pvForecastChargingModel,
                takeInstantEffect: 'true',
            })
            .then(() => {
                dispatch(onSuccessfulElectricCarChargingModeChange(configId));
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    `Error calling SetSmartChargingModeToPvForecast:`,
                );

                dispatch(setElectricCarChargingModeError(PvForecast, e));
            });
    };

export const useSetElectricCarChargingMode = (
    mode: GenericSmartChargingModelModeEnum,
) => {
    const dispatch = useDispatch();
    const configId = useElectricCarConfigId();

    return useCallback(
        (parameters: ChargingModeModel, onComplete?: UnknownFunction): void => {
            if (!configId) {
                return;
            }

            switch (mode) {
                case Schedule:
                    //case GridFriendlyCharging:
                    dispatch(
                        setElectricCarSmartSchedulingMode(
                            configId,
                            parameters as ScheduleChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                case Autopilot:
                    dispatch(
                        setElectricCarAutopilotMode(
                            configId,
                            parameters as AutopilotChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                case PvForecast:
                    dispatch(
                        setElectricCarPvForecastMode(
                            configId,
                            parameters as PvForecastChargingModel,
                            onComplete,
                        ),
                    );
                    break;

                /*
                case SolarAssistedCharging:
                    dispatch(
                        setSolarAssistedChargingMode(
                            configId,
                            parameters as SolarAssistedChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                case DynamicPriceCharging:
                    dispatch(
                        setDynamicPriceChargingMode(
                            configId,
                            parameters as DynamicPriceChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                case HomePlugAndCharge:
                    dispatch(
                        setPlugAndChargeMode(
                            configId,
                            parameters as PlugAndChargeModel,
                        ),
                    );*/
            }
        },
        [mode, configId, dispatch],
    );
};

export const getHistoricalElectricCarData = async (
    date: Date,
    res: HistoricalResolution,
    userState: AggregatedDataUserState,
    dispatch: Dispatch,
) => {
    if (!checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_READ])) {
        return Promise.resolve({ prevDay: {} });
    }

    let error = false;

    const emobilityAPI = new PvBatteryApi(createRequestConfiguration());
    const resolution = getResolution(res);
    const comparisonInterval = getComparisonInterval(res);
    const { startDate, endDate } = getRequestDates(date, res, false);

    const data = await emobilityAPI
        .pvBatteryGetElectricCarHistoryV2({
            fromDate: startDate,
            toDate: endDate,
            interval: resolution,
            comparisonInterval,
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error getting electric car historical data:');

            error = true;

            return [];
        });

    return handleHistoricalEmobilityData(
        date,
        res,
        error,
        dispatch,
        data,
        userState,
    );
};

export const getElectricCarChargingHistory =
    (fromDate: string, toDate: string) =>
    (dispatch: Dispatch): Promise<any> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_READ])) {
            return Promise.resolve();
        }

        dispatch({
            type: EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY,
            payload: {
                error: false,
                loading: true,
            },
        });

        return new PvBatteryApi(createRequestConfiguration())
            .pvBatteryGetElectricCarHistorySessionsV2({
                fromDate,
                toDate,
            })
            .then((data: ElectricCarHistorySessionV2ResponseModel[]) => {
                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY,
                    payload: { data },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error getting electric car charging history:',
                );

                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY_ERROR,
                    payload: {
                        error: true,
                    },
                });
            })
            .finally(() => {
                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY_LOADING,
                    payload: {
                        loading: false,
                    },
                });
            });
    };

import { useMemo } from 'react';
import { GatewayInvertersResponseModelKindEnum } from '@swagger-http';

import { useAppSelector } from '@store/selectors';

export const useHasGcp = () => useAppSelector((state) => state.energy.hasGCP);

export const useHasSolarCloud = () =>
    useAppSelector((state) => state.energy.hasSolarCloud);

export const useHasInverter = () =>
    useAppSelector((state) => state.energy.hasInverter);

export const useHasSmartMeter = () =>
    useAppSelector((state) => state.energy.hasSmartMeter);

export const useHasUkSmartMeter = () =>
    useAppSelector((state) => state.energy.hasUkSmartMeter);

export const useHasBattery = () =>
    useAppSelector((state) => state.energy.hasBattery);

export const useHasGasMeter = () =>
    useAppSelector((state) => state.energy.hasGasMeter);

export const useHasElectricityMeter = () =>
    useAppSelector((state) => state.energy.hasElectricityMeter);

export const useHasHeatPump = () =>
    useAppSelector((state) => state.energy.hasHeatPump);

export const useEnergyProvider = () =>
    useAppSelector((state) => state.energy.provider);

export const useLoggedIn = () => !!useAppSelector((state) => state.auth.token);

export const usePvPeak = (): number =>
    useAppSelector((state) => state.energy.pvPeakPower);

export const useHasUnknownInverterType = () =>
    useAppSelector(
        (state) =>
            !!state.energy.inverterTypes?.find(
                (inverter) =>
                    inverter.kind ===
                    GatewayInvertersResponseModelKindEnum.Unknown,
            ),
    );

export const useInverterDevices = () =>
    useAppSelector((state) =>
        state.energy.pvBatteryDevices?.filter(
            (device) => device.deviceConfiguration?.inverter?.installed,
        ),
    );

export const useInverters = () =>
    useInverterDevices()?.map(
        (inverter) => inverter.deviceConfiguration?.inverter,
    );

export const useGcpInverter = () =>
    useInverterDevices()?.find((d) => d.deviceConfiguration?.inverter?.gcp);

export const useBatteryDevice = () =>
    useAppSelector((state) =>
        state.energy.pvBatteryDevices?.find(
            (device) => device.deviceConfiguration?.battery?.installed,
        ),
    );

export const useBattery = () =>
    useBatteryDevice()?.deviceConfiguration?.battery;

export const useInverterTypes = () =>
    useAppSelector((state) => state.energy.inverterTypes);

export const useHeatPumpDevice = () =>
    useAppSelector((state) => state.energy.heatPumpDevice);
export const usePvPanelsData = () => {
    const pvbDevice = useAppSelector(
        (state) => state.energy.pvBatteryDevices?.[0],
    );
    const { orientation, peakPower } =
        pvbDevice?.deviceConfiguration?.panels?.[0] ?? {};
    return useMemo(
        () => ({
            azimuth: orientation?.azimuth ?? 0,
            tilt: orientation?.tilt ?? undefined,
            peakPower: peakPower ?? 0,
        }),
        [orientation, peakPower],
    );
};

export const useHeatPumpDeviceCurrentState = () =>
    useAppSelector((state) => state.energy.heatPumpDeviceState?.state?.current);

export const useGatewayLastScanDate = () => {
    const lastScanDate = useAppSelector(
        (state) => state.energy.gatewayLastScanDate,
    );

    return lastScanDate;
};
